import { render, staticRenderFns } from "./menuCategorias.vue?vue&type=template&id=c8c4805a&scoped=true&"
import script from "./menuCategorias.vue?vue&type=script&lang=js&"
export * from "./menuCategorias.vue?vue&type=script&lang=js&"
import style0 from "./menuCategorias.vue?vue&type=style&index=0&id=c8c4805a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8c4805a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VIcon,VList,VListItem,VListItemContent,VListItemTitle,VMenu,VSubheader})
