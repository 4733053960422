<template>
  <v-menu 
    offset-y
    open-on-hover
    style="background-color: #FFF;"
    content-class="menu-list"
  >
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
        class="selected_types_lancamentos"
      >{{ selectCategorias.length === 1 ? selectCategorias[0].name : `${selectCategorias.length} selecionados` }}</span>
    </template>
    <v-list
      flat
      dense
      height="300"
    >
      <v-list-item>
        <v-list-item-title class="title-list" @click="setCategories({ name: 'todas as categorias', id: 0 })">
          Todos
        </v-list-item-title>
      </v-list-item>
      <v-subheader>Receitas</v-subheader>
      <v-list-item
        v-for="(category, i) in categories.R"
        :key="i"
      >
      
        <v-list-item-content>
          <v-list-item-title class="title-list"  @click="setCategories(category)">
            {{category.name}}
            <v-icon :size="20" v-if="checked(category)" color="green">mdi-check-circle</v-icon>
          </v-list-item-title>
          <template v-if="category.children.length">
            <template  v-for="child in category.children">
              <v-list-item-content class="pl-4" :key="child.id">
                <v-list-item-title class="title-list" @click="setCategories(child)">
                  {{ child.name }}
                  <v-icon :size="20" v-if="checked(child)" color="green">mdi-check-circle</v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-list-item-content>

      </v-list-item>

      <v-subheader>Despesas</v-subheader>
      <v-list-item
        v-for="(category, i) in categories.D"
        :key="i"
      >
      
        <v-list-item-content>
          <v-list-item-title class="title-list" v-text="category.name" @click="setCategories(category)">
            {{ category.name }}
            <v-icon :size="20" v-if="checked(category)" color="green">mdi-check-circle</v-icon>
          </v-list-item-title>
          <template v-if="category.children.length">
            <template  v-for="child in category.children">
              <v-list-item-content class="pl-4" :key="child.id">
                <v-list-item-title class="title-list" @click="setCategories(child)">
                  {{ child.name }}
                  <v-icon :size="20" v-if="checked(child)" color="green">mdi-check-circle</v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-list-item-content>
      </v-list-item>

      <v-subheader>Outros</v-subheader>
      <v-list-item
        v-for="(category, i) in categories.O"
        :key="i"
      >
      
        <v-list-item-content>
          <v-list-item-title class="title-list" v-text="category.name" @click="setCategories(category)">
            {{ category.name }}
            <v-icon :size="20" v-if="checked(category)" color="green">mdi-check-circle</v-icon>
          </v-list-item-title>
          <template v-if="category.children.length">
            <template  v-for="child in category.children">
              <v-list-item-content class="pl-4" :key="child.id">
                <v-list-item-title class="title-list" @click="setCategories(child)">
                  {{ child.name }}
                  <v-icon :size="20" v-if="checked(child)" color="green">mdi-check-circle</v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "OrganizzeMenuCategorias",
  props: {
    categories: {
      type: Object,
      required: true
    },

    selectCategorias: {
      type: Array,
      required: true
    }
  },

  methods: {
    setCategories(category) {
      this.$emit('setCategories', category)
    },

    checked (category) {
      return !!this.selectCategorias.find(item => item.id === category.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/helpers/_variables.scss';
.menu-list {
  background-color: #FFF;

  .title-list {
    cursor: pointer;
  }
}

.selected_types_lancamentos {
  color: $colorPrimaryDarken;
}
</style>